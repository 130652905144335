export default function () {
  const { t } = useI18n();
  function convertValue (v: any) : any {
    if (typeof v === "string") {
      return parseFloat(v.replace(/,/g, ".").replace(/\s+/g, "")); // convert input with coma to dot e.g. 5,5 => 5.5
    }
    return v;
  }

  const postCodeRegex = /^\d{5}$|^\d{3} \d{2}$/;

  const rules = {
    required: (value: any): boolean | string => (value !== null && value !== undefined && value !== "") || t("form.validation.required"),
    max: (maxLength: number) => (value: any): boolean | string => !value || (value && value.length <= maxLength) || t("form.validation.maxLength", { maxLength }),
    min: (minLength: number) => (value: any): boolean | string => !value || (value && value.length >= minLength) || t("form.validation.minLength", { minLength }),
    maxValue: (maxValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) <= maxValue)) || t("form.validation.maxValue", { maxValue }),
    minValue: (minValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) >= minValue)) || t("form.validation.minValue", { minValue }),
    maxValueFloat: (maxValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) <= maxValue)) || t("form.validation.maxValue", { maxValue }),
    minValueFloat: (minValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) >= minValue)) || t("form.validation.minValue", { minValue }),
    isDecimal: (value: any): boolean | string => !value || (value && /^\d*([,.]\d+)?$/.test(value)) || t("form.validation.isNumber"),
    isNumber: (value: any): boolean | string => !value || (value && /^\d*$/.test(value)) || t("form.validation.isNumber"),
    isPositiveNumber: (value: any): boolean | string => !value || (value && /^\d+$/.test(value)) || t("form.validation.isPositiveNumber"),
    valueIsNotOnlyNumbers: (value: any): boolean | string => !value || (value && !/^\d*$/.test(value)) || t("form.validation.valueIsNotOnlyNumbers"),
    postCode: (value: any): boolean | string => !value || (value && postCodeRegex.test(value)) || t("form.validation.postCode"),
    isEmail: (value: any): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value as string) || t("form.validation.email");
    },
    isPhoneNumber: (value: any): boolean | string => !value || (!!value && /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value as string)) || t("form.validation.isPhoneNumber"),
    isLink: (value: any): boolean | string => !value || (!!value && /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi.test(value as string)) || t("form.validation.isLink")
  };

  return {
    rules
  };
}
